import "./Footer.scss";
import logo from "../../asset/logo 1.webp";
import map from "../../asset/map.png";
import { HashLink, NavHashLink } from "react-router-hash-link";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer id="footer" className="section-footer">
      <div className="container-footer">
        <div className="footer-top">
          <div className="footer-logo">
            <HashLink smooth to="/">
              <img src={logo} alt="logo altea boulevard cibubur" />
            </HashLink>
            <h3 className="footer-logo-title">Marketing Gallery</h3>
            <div className="footer-logo-text">Altea Boulevard Cibubur </div>
          </div>
          <div className="footer-sitemap">
            <div className="footer-site-link">
              <div className="footer-menu">
                Peta Situs
                <li className="nav-item">
                  <NavHashLink smooth to="/#about" className="nav-links">
                    About us
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#promo" className="nav-links">
                    Promo
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#rumah" className="nav-links">
                    Our Home
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#surrounding" className="nav-links">
                    Surrounding Area
                  </NavHashLink>
                </li>
                <li className="nav-item" onClick={scrollToTop}>
                  <a href="/" className="nav-links">
                    Back to Top
                  </a>
                </li>
              </div>
              <div className="footer-map">
                <div className="footer-map-title">Lokasi</div>
                <img
                  src={map}
                  alt="map altea boulevard cibubur"
                  className="footer-map-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-rights">
          © 2024 Altea Boulevard Cibubur . All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
