import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Navbar from "./component/navbar/Navbar.jsx";
import Footer from "./component/footer/Footer.jsx";

function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <Navbar />
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=6282380709997&text=Halo+Mario.%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ini%20(Altea%20Boulevard%20Cibubur)%20%20http://alteablvd-cibubur.id/&type=phone_number&app_absent=0"
        >
          <FloatingWhatsApp />
        </a>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
